* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--fontRegular);
}

html {
    scroll-behavior: smooth;
}

body, .gelpCx, .asset-table {
    &::-webkit-scrollbar {
        width: 10px;
        height: 12px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--bgColor);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--border);
        border-radius: 20px;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontBold) !important;
}

iframe {
    border: 0;
}

a {
    text-decoration: none !important;
    &:hover {
        text-decoration: none !important;
    }
}

button {
    outline: none;
    border: none;
    &:focus {
        outline: none;
    }
}

ul {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
}

img {
    max-width: 100%;
}

p {
    margin-bottom: 0 !important;
}

strong {
    font-weight: 700;
}

input, select, textarea {
    &:focus {
        box-shadow: none !important;
        outline: none !important;
    }
}

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

body {
    background-color: var(--bgColor);
    overflow: visible !important;
    overflow-x: hidden !important;      
    padding: 0 !important;
}

.total-assets {
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    background-color: var(--primary);
    color: var(--white);
    border-radius: 4px;
    position: absolute;
    left: 40px;
    bottom: 60px;
    z-index: 2;
    @media(max-width: 1600px) {
        font-size: 13px;
    }
}

.ui.table {
    td {
        outline-color: var(--primary) !important;
        
        &.active {
            background: var(--redBg) !important;
        }
    }
}

// Dropdown
.dropdown {
    .ui.selection.dropdown {
        padding: 0 !important;
        border: none !important;
        background: transparent !important;
        min-height: auto !important;
        max-width: 150px;
        height: 35px !important;
        display: flex;
        align-items: center;
    }

    .dropdown-icon {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 20px;
        bottom: 11px;
        @media(max-width: 1440px) {
            bottom: 8px;
        }
    }
}

// Progress bar
.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--white);
    z-index: 9999;

    &__bar {
        display: block;
        width: 100%;
        height: 25px;
        padding: 5px;
        background-color: var(--border);
        border-radius: 20px;
        overflow: hidden;
        max-width: 50%;
        position: relative;
    }

    &__active {
        display: block;
        height: 15px;
        border-radius: 20px;
        background-image: var(--progressBg);
        transition: width 0.5s ease-in-out;
    }

    &__title {
        display: block;
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        color: var(--textColor);
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &__logo {
        display: inline-block;
        height: 80px;
        object-fit: contain;
        margin-bottom: 60px;
    }

    &__text {
        display: inline-block;
        font-size: 14px;
        color: var(--textLite);
        margin-top: 20px;
        text-transform: uppercase;
    }

    &__dot {
        display: inline-block;
        font-size: 40px;

        &:nth-child(1) {
            animation: dots .5s infinite alternate linear;
        }

        &:nth-child(2) {
            animation: dots 1s infinite alternate linear;
        }

        &:nth-child(3) {
            animation: dots 1.5s infinite alternate linear;
        }
    }
}

@keyframes dots {
    from {color: #EA2A2A; transform: translate(0, -10%);}
    to {color: #2D353C; transform: translate(0, 0);}
}