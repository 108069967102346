.error {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background-color: var(--bgLite);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__title {
        display: block;
        font-size: 34px;
        font-weight: 300;
        color: var(--textColor);
        margin: 0 0 5px;
    }

    &__subtitle {
        display: block; 
        font-size: 18px;
        font-weight: 300;
        color: var(--textLite);
    }

    &__img {
        display: block;
        max-width: 100%;
        height: 250px;
        margin: 40px 0;
    }

    &__text {
        font-size: 16px;
        color: var(--textLite);
        max-width: 60%;
        font-weight: 300;
        margin-bottom: 25px !important;
    }

    &__btn {
        min-width: 200px;

        svg {
            width: 22px;
            height: 22px;
            margin-right: 10px;
        }

        span {
            top: 0;
        }
    }

    &__link {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        color: var(--primary);
        font-size: 16px;
        transition: var(--transition-sm);
        &:hover {
            color: var(--textColor);
        }

        svg {
            width: 22px;
            height: 22px;
            position: relative;
            top: -1px;
        }
    }
}