.sidebar {
    position: fixed;
    top: 65px;
    left: 0;
    width: 83px;
    background-color: var(--accent);
    height: calc(100vh - 65px);
    padding: 65px 0 0;
    z-index: 999;
    transition: var(--transition-md);
    @media(max-width: 1440px) {
        height: calc(100vh - 55px);
        top: 55px;
    }
    @media(max-width: 992px) {
        height: 100%;
        width: 100%;
        left: -100%;
        top: 0;
    }

    &.open {
        width: 250px;
        @media(max-width: 1440px) {
            width: 220px;
        }
        @media(max-width: 992px) {
            width: 100%;
            left: 0;
        }
        .sidebar__link {
            padding: 5px 0 5px 15px;
            justify-content: flex-start;
            gap: 5px;

            span {
                opacity: 1;
                display: block;
            }

            img {
                width: 22px;
                height: 22px;
            }
        }

        .sidebar__submenu {
            .sidebar__link {
                padding-left: 35px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .sidebar__toggle {
            svg {
                transform: rotate(-180deg);
            }
        }

        .toggle-icon {
            display: inline-block;
            opacity: 1;
        }
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 35px;
        background-color: var(--accentDark) !important;
        color: var(--white);
        border-radius: 30px 0 0 30px;
        position: absolute;
        right: 0;
        top: 15px;
        min-width: auto;
        padding: 0;
        z-index: 1;
        transition: var(--transition-sm);
        box-shadow: none !important;
        @media(max-width: 992px) {
            display: none;
        }

        svg {
            transform: rotate(0deg);
            transition: var(--transition-sm);
        }
    }

    &__menu {
        ul {
            li {
                border-bottom: 1px solid var(--sidebarBorder);
                transition: var(--transition-sm);
                transition-delay: .1s;
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    &__link {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        width: 100%;
        padding: 0;
        background-color: var(--accent);
        color: var(--white);
        padding: 0 29px;
        font-size: 14px;
        position: relative;
        border-radius: 0;
        transition: var(--transition-sm);
        @media(max-width: 1600px) {
            font-size: 12px;
        }
        @media(max-width: 992px) {
            height: 60px;
        }

        &:hover {
            background-color: var(--accentDark);
            color: var(--white);
        }

        &.active {
            color: var(--white);
            background-color: var(--primary);

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            width: 25px;
            height: 25px;
            object-fit: contain;
            margin: 0;
            transition: var(--transition-sm);
        }

        span {
            font-family: var(--fontAlt) !important;
            font-weight: 600;
            white-space: nowrap;
            text-align: left;
            padding: 0 5px;
            opacity: 0;
            display: none;
            transition: var(--transition-md);
        }
    }

    &__logout {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transition: var(--transition-sm);
        @media(max-width: 992px) {
            bottom: 40px;
        }

        &.place {
            position: relative;
            @media(max-width: 992px) {
                bottom: 0;
            }
        }
    }

    &__submenu {
        transition: var(--transition-sm);

        li {
            transition: var(--transition-md);
            border: none;
        }

        .sidebar__link {
            font-size: 13px;
            padding: 15px 30px;
            height: 45px;
            @media(max-width: 1600px) {
                font-size: 12px;
            }

            img {
                width: 22px;
                height: 22px;
            }

            span {
                font-weight: 400;
                transition: var(--transition-md);
            }
        }
    }

    &__subtoggle {
        position: relative;
        &:hover {
            .toggle-icon {
                svg {
                    color: var(--white);
                }
            }
        }

        &.open {
            .toggle-icon {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .toggle-icon {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            z-index: 1;
            color: var(--primary);
            display: none;
            opacity: 0;
            transition: var(--transition-md);
        }
    }
}

.mobile-toggle {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1001;
    width: 65px;
    height: 65px;
    padding: 0;
    min-width: auto;
    background-color: var(--primary) !important;
    border-radius: 0;

    svg {
        margin: 0;
        color: var(--white);

        @media (max-width: 1440px) {
            width: 25px !important;
            height: 25px !important;
            margin-right: 5px;
        }
    }

    @media(max-width: 992px) {
        display: flex;
    }
}