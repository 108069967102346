.chart-date {
    display: block;
    text-align: center;
    font-size: 21px;
    color: var(--textColor);
    margin-bottom: 30px;
    font-family: var(--fontRegular) !important;
}

.apexcharts-legend-series {
    margin-right: 10px !important;
}

.apexcharts-legend-marker {
    width: 20px !important;
    height: 20px !important;
    border-radius: 3px !important;
    @media(max-width: 1440px) {
        width: 16px !important;
        height: 16px !important;
    }
}

.apexcharts-canvas {
    margin: 0 auto;
}

.legends {
    display: block;
    position: relative;
    width: 100%;
    @media(max-width: 992px) {
        margin-top: 20px;
    }

    &.radialbars {
        .legends__color {
            &.red {
                background: linear-gradient(90deg, #FF0000 50%, #00B050 50%);
            }

            &.yellow {
                background: linear-gradient(90deg, #E1E103 50%, #00B050 50%);
            }

            &.green {
                background: linear-gradient(90deg, #EA2A2A 50%, #E1E103 50%);
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        width: 100%;
        background-color: var(--white);
        border-radius: 10px;
        box-shadow: 0 0 12px rgba(0,0,0,0.06);
        padding: 10px 20px;
        overflow: hidden;
        margin-bottom: 15px;
        cursor: pointer;
        transition: var(--transition-sm);
        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            box-shadow: 0 5px 12px rgba(0,0,0,0.08);
            transform: translateY(-5px);
        }

        &.disabled {
            pointer-events: none;
        }

        @media(max-width: 1600px) {
            padding: 10px;
        }
        @media(max-width: 1440px) {
            gap: 10px;
        }

        div {
            @media(max-width: 1440px) {
                width: calc(100% - 30px);
            }
        }
    }

    &__color {
        display: inline-block;
        width: 40px;
        height: 25px;
        border-radius: 20px;
        @media(max-width: 1440px) {
            width: 30px;
            height: 18px;
        }

        &.total {
            background-color: var(--chartTotal);
        }
        &.red {
            background-color: var(--chartRed);
        }
        &.yellow {
            background-color: var(--chartYellow);
        }
        &.green {
            background-color: var(--chartGreen);
        }
        &.detail {
            background-color: var(--chartDetail);
        }
        &.close {
            background-color: var(--chartClose);
        }
        &.visual {
            background-color: var(--chartVisual);
        }
    }

    &__title {
        display: block;
        font-size: 17px;
        color: var(--color1);
        margin: 0;
        @media(max-width: 1600px) {
            font-size: 12px;
        }
        @media(max-width: 1366px) {
            font-size: 10px;
        }
    }

    &__count {
        display: block;
        font-size: 14px;
        color: var(--iconColor);
        margin: 0;
        @media(max-width: 1600px) {
            font-size: 12px;
        }
        @media(max-width: 1366px) {
            font-size: 10px;
        }
    }
}

.chart-filter {
    position: relative;
    @media(max-width: 992px) {
        width: 100%;
    }

    &__toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-width: 150px;
        max-width: 150px;
        height: 50px;
        padding: 18px 20px;
        background-color: transparent;
        border: 1px solid var(--inputBorder);
        border-radius: 5px;
        transition: var(--transition-sm);
        &::before {
            content: '';
            width: 100vw;
            height: 100vh;
            position: fixed;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: 1;
            display: none;
        }

        @media(max-width: 1440px) {
            min-width: 130px;
            max-width: 130px;
            height: 44px;
            padding: 18px 15px;
        }
        @media(max-width: 992px) {
            width: 100%;
            max-width: none;
            min-width: auto;
        }

        &.active {
            svg {
                transform: rotate(180deg);
            }

            &::before {
                display: block;
            }
        }

        .toggle-label {
            font-size: 17px;
            color: var(--primary);
            @media(max-width: 1440px) {
                font-size: 13px;
            }
        }

        svg {
            color: var(--iconColor);
            transition: var(--transition-sm);
        }
    }

    &__dropdown {
        width: 150px;
        padding: 10px;
        background-color: var(--white);
        border-radius: 5px;
        box-shadow: 0 3px 12px rgba(0,0,0,0.08);
        position: absolute;
        right: 0;
        top: 55px;
        z-index: 9997;
        @media(max-width: 1440px) {
            width: 130px;
        }
        @media(max-width: 992px) {
            width: 100%;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 30px;
        background-color: var(--white);
        color: var(--textColor);
        border: 1px solid var(--buttonBg);
        border-radius: 3px;
        margin-bottom: 3px;
        transition: var(--transition-sm);
        &.active {
            background-color: var(--primary);
            color: var(--white);
        }

        &:hover {
            background-color: var(--buttonBg);
        }

        &:last-child {
            margin-bottom: 0;
        }

        @media(max-width: 1440px) {
            font-size: 13px;
            height: 25px;
        }
        @media(max-width: 992px) {
            height: 45px;
        }
    }
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left, 
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left, 
.apexcharts-legend.apx-legend-position-right, 
.apexcharts-legend.apx-legend-position-left {
    @media(max-width: 540px) {
        flex-direction: column;
        gap: 5px;
    }
}

.apexcharts-legend.apexcharts-align-right .apexcharts-legend-series, 
.apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    @media(max-width: 540px) {
        display: flex !important;
    }
}

.chart-flex {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-wrap: wrap;
    @media(max-width: 1080px) {
        flex-direction: row-reverse;
    }
    @media(max-width: 540px) {
        flex-direction: column;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:first-child {
            width: 60%;
            @media(max-width: 1080px) {
                width: 60%;
            }
            @media(max-width: 540px) {
                width: 100%;
            }
        }
        &:last-child {
            width: 40%;
            padding-left: 20px;
            @media(max-width: 1440px) {
                padding-left: 15px;
            }
            @media(max-width: 1366px) {
                padding-left: 10px;
            }
            @media(max-width: 1080px) {
                padding-left: 20px;
                padding-top: 0;
                width: 40%;
            }
            @media(max-width: 540px) {
                padding-left: 0;
                padding-top: 30px;
                width: 100%;
            }
        }
    }
}

.apexcharts-radialbar {
    .apexcharts-datalabel-label {
        fill: var(--textColor) !important;
    }
}

// Column Chart Legends
.columnLegends {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        margin: 2px 8px;
        cursor: pointer;
        transition: var(--transition-sm);
        &:hover {
            opacity: 0.5;
        }

        &.disabled {
            pointer-events: none;

            .columnLegends__color {
                border-radius: 50%;
            }
        }
    }

    &__color {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 3px;
    }

    &__label {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: var(--textColor);
        margin-top: 3px;
    }
}

.no-data-found {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    color: var(--textColor);
}