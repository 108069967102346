.aimmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    transition: var(--transition-md);

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
        z-index: 9998;
        background-color: var(--black);
        opacity: 0.05;
        transition: var(--transition-md);
    }

    &__wrapper {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--white);
        position: relative;
        border-radius: 5px;
        max-width: 500px;
        width: 100%;
        z-index: 9999;
        box-shadow: var(--boxShadow);
        transition: var(--transition-md);
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 20px 0 0;
    }

    &__content {
        text-align: center;
        padding: 20px 30px 50px;
    }

    &__close {
        padding: 0;
        background-color: transparent;
        cursor: pointer;
    }

    &__title {
        display: block;
        font-size: 25px;
        color: var(--primary);
        margin-bottom: 15px;
    }

    &__text {
        display: block;
        font-size: 16px;
        color: var(--textColor);
        max-width: 80%;
        margin: 0 auto;
        white-space: break-spaces;
        @media(max-width: 992px) {
            max-width: none;
        }
    }

    &__btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 25px;
        margin-top: 25px;
    }
}

// Custom Layout Popup

.layout {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
        z-index: 9998;
        background-color: rgba(0, 0, 0, 0.5);
        transition: var(--transition-md);
    }

    &__wrapper {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--white);
        position: relative;
        border-radius: 8px;
        max-width: 850px;
        width: 100%;
        z-index: 9999;
        box-shadow: var(--boxShadow);
        transition: var(--transition-md);
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 12px 30px;
        background-color: var(--primary);
        border-radius: 8px 8px 0 0;
    }

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        color: var(--white);
        font-family: var(--fontRegular) !important;
        margin: 0;

        img {
            width: 35px;
            height: 35px;
            object-fit: contain;
        }
    }

    &__content {
        background-color: var(--white);
        padding: 30px;
        border-radius: 0 0 8px 8px;
    }

    &__list {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;
        gap: 20px 0;
        overflow-y: auto;
        max-height: 220px;

        &--item {
            display: inline-block;
            width: 25%;
        }
    }

    &__close {
        display: inline-block;
        
        svg {
            color: var(--white);
        }
    }
}

.downloading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    position: fixed;
    bottom: 20px;
    right: 0;
    z-index: 999;

    &__progress {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        background-color: var(--white);
        border-radius: 4px;
        padding: 10px 15px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        opacity: 0;
        visibility: visible;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    &__text {
        display: block;
        font-size: 14px;
        color: var(--textColor);
        position: relative;
        top: 2px;
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 35px;
        border-radius: 4px 0 0 4px;
        background-color: var(--white);
        color: var(--textLite);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
}
