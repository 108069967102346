.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 65px;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    z-index: 999;
    @media(max-width: 1440px) {
        padding: 8px 20px;
        height: 55px;
    }
    @media(max-width: 992px) {
     padding: 10px 80px 10px 15px;   
     height: 65px;
    }

    &__logo {
        display: inline-block;
        height: 40px;
        overflow: hidden;
        @media(max-width: 1440px) {
            height: 25px;
        }

        img {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__menu {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__user {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 5px 10px 5px 10px;
        height: 45px;
        border: 1px solid var(--borderLite);
        border-radius: 5px;
        background-color: var(--white);
        max-width: 200px;

        &::before {
            content: '';
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1; 
            display: none;
        }

        &.open {
            &::before {
                display: block;
            }
        }

        @media(max-width: 1440px) {
            padding: 5px;
            height: 40px;
        }
        &.open {
            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            color: var(--primary);
            transition: var(--transition-sm);
            @media(max-width: 1440px) {
                width: 18px;
                height: 18px;
            }
        }

        .user-name {
            font-size: 16px;
            color: var(--color1);
            margin: 0 10px;
            @media(max-width: 1440px) {
                font-size: 14px;
                margin: 0 5px;
            }
            @media(max-width: 540px) {
                margin: 0;
            }
        }
    }

    &__logout {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 15px 5px 15px;
        height: 45px;
        border: 1px solid var(--borderLite);
        border-radius: 5px;
        background-color: var(--white);
        max-width: 200px;
        gap: 10px;
        color: var(--textColor);
        font-size: 16px;
        transition: var(--transition-sm);

        @media(max-width: 1440px) {
            font-size: 14px;
        }
        
        &:hover {
            background-color: var(--primary);
            color: var(--white);
            border-color: var(--primary);
            
            svg {
                color: var(--white);
            }
        }
        
        svg {
            color: var(--primary);
            transition: var(--transition-sm);
            @media(max-width: 1440px) {
                width: 18px;
                height: 18px;
            }
        }
    }

    .user-image {
        height: 40px;
        overflow: hidden;
        margin-left: 15px;
        @media(max-width: 1440px) {
            height: 25px;
        }

        img {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__dropdown {
        position: absolute;
        left: -10px;
        top: 55px;
        overflow: hidden;
        border-radius: 0 0 8px 8px;
        background-color: var(--white);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.04);
        min-width: 130px;
        max-width: 130px;
        opacity: 0;
        height: 0;
        pointer-events: none;
        transition: var(--transition-sm);
        z-index: 99;
        &.open {
            height: 43px;
            opacity: 1;
            pointer-events: all;
        }

        @media(max-width: 1440px) {
            top: 45px;
            left: -20px;
            min-width: 100px;
            max-width: 100px;
        }
        @media(max-width: 540px) {
            top: 55px;
            right: 60px;
        }

        .dropdown-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid var(--color2);
            gap: 15px;
            color: var(--color1);
            transition: var(--transition-sm);
            &:hover {
                background-color: var(--primary);
                color: var(--white);
                border-color: var(--primary);

                svg {
                    color: var(--white);
                }
            }

            &:last-child {
                border-bottom: 0;
            }

            @media(max-width: 1440px) {
                font-size: 13px;
            }

            svg {
                color: var(--primary);
                transition: var(--transition-sm);
            }
        }
    }
}