// Variables

:root {
    --white         : #FFFFFF;
    --black         : #000000;
    --yellow        : #FCCC04;
    --yellowBg      : #FFFFE7;
    --red           : #EA2A2A;
    --redBg         : #ffd6d6;
    --redDark       : #D10404;
    --green         : #3BB449;
    --greenBg       : rgba(59, 180, 73, 0.2);

    --primary       : #EA2A2A;
    --accent        : #2D353C;
    --accentDark    : #242A30;
    --bgColor       : #F5F6F9;
    --bgLite        : #FAFAFA;

    --textColor     : #242A30;
    --textLite      : #707072;
    --textAlt       : #6B6B6B;
    --iconColor     : #8C8B99;
    --border        : #BFBFBF;
    --inputBorder   : #CBCBCB;
    --borderAlt     : #AEADB7;
    --borderLite    : #E0E0E0;
    --loginBorder   : #C2C2C2;
    --sidebarBorder : #333b42;

    --buttonBg      : #EBEBEB;
    --uploadBg      : #F4F4FB;

    --bgGradient    : linear-gradient(to right, var(--white) 50%, var(--accent) 50%);
    --accordClose   : linear-gradient(0deg, rgba(117,21,21,1) 0%, rgba(234,42,42,1) 100%);
    --accordOpen    : linear-gradient(0deg, rgba(88,18,18,1) 0%, rgba(209,2,2,1) 100%);
    --progressBg    : linear-gradient(0deg, rgba(117,21,21,1) 0%, rgba(234,42,42,1) 100%);

    --color1        : #555555;
    --color2        : #F0F0F2;
    --color3        : #FBFBFB;
    --color4        : #F5F5F5;
    --color5        : #B2B2B2;

    --chartTotal    : #585656;
    --chartRed      : #FF0000;
    --chartYellow   : #E1E103;
    --chartGreen    : #00B050;
    --chartDetail   : #0678EB;
    --chartClose    : #62AEFB;
    --chartVisual   : #CBE4FE;

    --boxShadfow    : 0 3px 6px rgba(0,0,0,0.05);

    --fontRegular   : 'halveticaRegular';
    --fontBold      : 'halveticaBold';
    --fontSpecial   : 'gilroy';
    --fontAlt       : 'Open Sans', sans-serif;;

    --transition-sm : all .3s ease-in-out;
    --transition-md : all .5s ease-in-out;
}