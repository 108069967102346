.app {
    &__btns {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 25px;
        @media(max-width: 1440px) {
            gap: 15px;
        }
        @media(max-width: 992px) {
            width: 100%;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        &.submit {
            align-items: flex-end;
            margin-top: 10px;

            a, button {
                width: auto !important;
                @media(max-width: 540px) {
                    width: 100% !important;
                }
            }
        }

        a, button {
            @media(max-width: 540px) {
                width: 47% !important;
            }

            &:first-child {
                @media(max-width: 540px) {
                    width: 100% !important;
                }
            }
        }
    }
}

.deleteLayout-button:hover {
    color: rgb(10, 10, 10);
    transform: scale(1.2);
}

.click {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid transparent;
    min-width: 100px;
    overflow: hidden;
    outline: none;
    transition: all .3s ease;
    
    &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
    }
    
    &:active {
        transform: scale(1);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    }

    @media(max-width: 1440px) {
        min-width: 80px;
        padding: 10px 15px;
    }

    @media(max-width: 540px) {
        width: 100%;
    }

    svg {
        margin-right: 12px;
        @media(max-width: 2000px) {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
        @media(max-width: 1440px) {
            width: 17px;
            height: 17px;
            margin-right: 5px;
        }
    }

    img {
        height: 18px;
        object-fit: contain;
        margin-right: 12px;
        @media(max-width: 1440px) {
            height: 15px;
        }
    }

    span {
        position: relative;
        top: 2px;
    }

    &.disable {
        pointer-events: none;
        opacity: 0.7;
        cursor: not-allowed;
        display: none;
    }

    &.click-sm {
        height: 45px;
        font-size: 15px;
        @media(max-width: 1600px) {
            height: 40px;
        }
        @media(max-width: 1440px) {
            font-size: 13px;
        }
        @media(max-width: 992px) {
            font-size: 14px;
        }
    }

    &.click-lg {
        height: 50px;
        font-size: 16px;
        @media(max-width: 1600px) {
            // height: 45px;
        }
        @media(max-width: 1440px) {
            font-size: 14px;
        }
    }

    &.click-xl {
        height: 55px;
        font-size: 20px;
        line-height: 55px;
        @media(max-width: 1600px) {
            height: 50px;
            font-size: 18px;
        }
        @media(max-width:  1440px) {
            font-size: 16px;
        }
    }

    &.click-resize {
        min-width: auto;
        padding: 0;
    }

    &.click-primary {
        background-color: var(--primary);
        color: var(--white);

        svg {
            color: var(--white);
        }

        img {
            filter: brightness(0) invert(1);
        }
    }

    &.click-accent {
        background-color: var(--accent);
        color: var(--white);

        svg {
            color: var(--white);
        }

        img {
            filter: brightness(0) invert(1);
        }
    }

    &.click-info {
        background-color: var(--white);
        color: var(--textAlt);
        border-color: var(--borderAlt);

        svg {
            color: var(--iconColor);
        }
    }

    &.click-gray {
        background-color: var(--buttonBg);
        color: var(--textColor);
    }

    &.click-none {
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none !important;
        transform: none !important;

        svg {
            margin: 0;
        }
    }
}

.asset-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 80px;
    @media(max-width: 1600px) {
        gap: 70px;
    }
    @media(max-width: 1366px) {
        gap: 50px;
    }
    @media(max-width: 1280px) {
        // flex-wrap: wrap;
        gap: 30px;
    }
    @media(max-width: 992px) {
        gap: 30px;
        flex-direction: column;
    }
}

.asset-view-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    background-color: var(--white);
    border: 1px solid var(--borderAlt) !important;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.06);
    height: 95px;
    width: 100%;
    transform: translateY(0);
    transition: var(--transition-sm);
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 8px 0 rgba(0,0,0,0.1);
    }

    @media(max-width: 1440px) {
        height: 70px;
    }
    @media(max-width: 1280px) {
        width: 48%;
    }
    @media(max-width: 992px) {
        width: 100%;
    }

    .view-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95px;
        height: 95px;
        overflow: hidden;
        border-radius: 8px 0 0 8px;
        background-color: var(--primary);
        position: relative;
        left: -1px;

        @media(max-width: 1440px) {
            height: 70px;
            width: 70px;
        }

        img {
            width: 60px;
            height: 60px;
            object-fit: contain;
            margin: 0;
            @media(max-width: 1440px) {
                width: 40px;
                height: 40px;
            }
        }
    }

    .view-title {
        font-size: 18px;
        font-family: var(--fontAlt);
        font-weight: 700;
        color: var(--textColor);
        padding-left: 40px;
        letter-spacing: -0.3px;
        @media(max-width: 1440px) {
            font-size: 14px;
        }
        @media(max-width: 1087px) {
            font-size: 14px;
            padding-left: 5px;
        }
        @media(max-width: 992px) {
            padding-left: 25px;
            font-size: 16px;
        }
    }
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;

    &__data {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }

    &__label {
        font-size: 16px;
        color: var(--textLite);
        @media(max-width: 1440px) {
            font-size: 13px;
        }
    }

    &__divider {
        font-size: 25px;
        color: var(--primary);
        @media(max-width: 1440px) {
            font-size: 22px;
        }
    }

    &__value {
        font-size: 16px;
        color: var(--primary);
        @media(max-width: 1440px) {
            font-size: 13px;
        }
    }
}

.btn-link {
    text-decoration: none;
    @media(max-width: 540px) {
        width: 100%;
    }
}

.filter-btns {
    .click-sm {
        min-width: 108px;
        @media(max-width: 1440px) {
            min-width: 90px;
        }
        @media(max-width: 540px) {
            min-width: auto;
        }
    }
}

.MuiButton-textPrimary {
    color: var(--primary) !important;
}
.asset-view-btn {
    width: 100%;
}