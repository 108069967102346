.inspection-table {
    max-width: 82%;
    padding-left: 190px;

    @media(max-width: 1600px) {
        max-width: 79%;
        padding-left: 0;
        margin: 0 auto;
    }

    @media(max-width: 1440px) {
        max-width: 82%;
        padding-left: 0;
        margin: 0 auto;
    }

    @media(max-width: 1366px) {
        max-width: 81%;
    }

    @media(max-width: 1280px) {
        max-width: 79%;
    }

    @media(max-width: 1080px) {
        max-width: 73%;
    }

    @media(max-width: 992px) {
        max-width: 100%;
    }

    .rdt_TableCol {
        background-image: var(--accordClose) !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
        border-right: 1px solid rgba(0, 0, 0, 0.10);
        font-size: 15px;
        letter-spacing: 0.5px;

        &:last-child {
            border: none;
        }

        @media(max-width: 1440px) {
            padding-left: 15px !important;
            padding-right: 15px !important;
            font-size: 13px !important;
        }
    }

    .rdt_TableCell {
        border: 1px solid var(--borderLite);
        border-top: 0;
        padding: 30px 40px;
        font-size: 16px;
        color: var(--textColor);
        line-height: 1.4;

        &:first-child {
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-bottom-right-radius: 4px;
            border-left: 0;
        }

        @media(max-width: 1366px) {
            padding: 20px 15px;
            font-size: 14px;
        }
    }

    .bMgaAx div:first-child {
        white-space: unset;
    }
}

.rdt_TableCell,
.rdt_TableCol {
    justify-content: center;

    input[type="checkbox"] {
        width: 18px;
        height: 18px;
    }
}

.rdt_TableCol {
    background-color: var(--accent);
    color: var(--white);
    font-size: 15px;

    div {
        &:first-child {
            overflow: visible;
        }
    }
}

.rdt_TableHeadRow {
    border-radius: 3px 5px 0 0;
    overflow: hidden;

    @media(max-width: 1440px) {
        min-height: 40px !important;
        max-height: 40px !important;
    }
}

.rdt_TableRow {
    border-bottom-color: var(--borderLite) !important;

    &:nth-child(odd) {
        .rdt_TableCell {
            background-color: var(--white);
        }
    }

    &:nth-child(even) {
        .rdt_TableCell {
            background-color: var(--color3);
        }
    }

    .rdt_TableCell {
        font-size: 15px;
        color: var(--textColor);
        padding-right: 35px;

        &:last-child {
            padding-right: 15px;
        }

        div {
            &:first-child {
                overflow: visible;
                text-align: center;
            }
        }
    }
}

.rdt_Pagination {
    background-color: var(--color3) !important;
}

.table-align-left {
    .rdt_TableRow {
        .rdt_TableCell {
            padding-right: 0 !important;

            @media(max-width: 1440px) {
                font-size: 13px !important;
            }

            div {
                &:first-child {
                    text-align: left;
                }
            }
        }
    }

    .rdt_TableCell,
    .rdt_TableCol {
        justify-content: flex-start !important;
    }
}

.delete-btn {
    border: none;
    outline: none;
    background: transparent;
    margin: 0 10px;

    &.mg-auto {
        margin: 0 auto !important;
    }

    svg {
        color: var(--red);
    }
}

.external-btn {
    svg {
        color: var(--textLite);
    }
}


// Asset Register Table

.asset-table {
    width: 100%;
    overflow: auto;
    max-height: 820px;
    @media(max-width: 1600px) {
        max-height: 470px;
    }

    &.selectable {
        .table {
            td {
                &:first-child {
                    padding-left: 12px !important;
                    padding-right: 0 !important;
                }

                &:last-child {
                    padding-right: 10px !important;
                }
            }
        }
    }

    &.checklist-table {
        th {
            text-align: left !important;
            border-right: 1px solid var(--borderLite);

            &:last-of-type {
                border-right: 0;
            }
        }

        td {
            white-space: inherit !important;
            text-align: left !important;
            border: 1px solid var(--borderLite);
        }
    }

    &.failure-table {
        .table thead .click {
            margin: 0 !important;
            justify-content: flex-start;
        }

        .table th, .table td {
            padding: 8px 10px !important;
        }
    }

    &.corrective-table {
        .table {
            td {
                line-height: 3 !important;
            }
        }
    }

    .checkbox-wrapper .checkbox-label .check-icon {
        top: -3px;
    }

    .table {
        border-radius: 5px 5px 0 0;
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0;

        tr {
            transition: var(--transition-sm);

            &:hover {
                td {
                    background-color: var(--redBg) !important;
                }
            }

            &.hilite {
                td {
                    background-color: var(--greenBg);
                }
            }
        }

        th,
        td {
            white-space: nowrap;
            font-family: var(--fontRegular) !important;
            font-weight: normal !important;
            padding: 0 10px;
            font-size: 14px;
            text-align: center;
            vertical-align: middle;

            @media(max-width: 1600px) {
                font-size: 12px;
            }

            a {
                color: var(--textColor);
                transition: var(--transition-sm);

                &:hover {
                    color: var(--primary);
                }
            }
        }

        tbody {
            overflow-x: auto;
        }

        thead {
            position: sticky;
            top: 0;
            z-index: 9;
            th {
                background-color: var(--accent);
                color: var(--white);
                padding: 8px;

                @media(max-width: 1600px) {
                    padding: 5px 8px;
                }
            }

            .click {
                padding: 0;
                color: var(--white);
                box-shadow: none !important;
                transform: none !important;
                font-size: 14px;
                margin: 0 auto;
                height: auto;

                @media(max-width: 1600px) {
                    font-size: 12px;
                }

                svg {
                    opacity: 0.2;
                    margin-left: 5px;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    &.spacing-in {
        .table {

            th,
            td {
                padding: 8px 10px;
                line-height: normal;
            }
        }
    }

    &.table-align-left {

        th,
        td {
            text-align: left;
        }
    }

    .sort-btn {
        &.align-left {
            justify-content: flex-start !important;
            margin: 0 !important;
        }
    }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--white);
}

.table-striped>tbody>tr:nth-of-type(even)>* {
    background-color: var(--color3);
}

.text-left {
    text-align: left !important;

    .click {
        margin: 0 !important;
        text-align: left;
    }

    &.findings {
        .click {
            margin-left: -8px !important;
        }
    }
}

.table-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .click {
        margin: 0 !important;
        padding: 5px;
        box-shadow: none !important;
        transform: none !important;
    }
}


// Pagination

.pagination-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    background-color: var(--white);
    border-radius: 0 0 5px 5px;

    @media(max-width: 540px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .pagination-count {
        display: inline-block;
        font-size: 15px;
        color: var(--textAlt);

        @media(max-width: 1440px) {
            font-size: 13px;
        }

        @media(max-width: 540px) {
            margin-bottom: 15px;
        }

    }
}




.pagination {
    gap: 10px;

    .page-link {
        border-radius: 3px;
        color: var(--textLite) !important;
        height: 33px !important;
        line-height: 23px;

        @media(max-width: 1440px) {
            font-size: 13px !important;
        }

        &:focus,
        &:active {
            box-shadow: none !important;
        }
    }

    .page-item {
        &.active {
            .page-link {
                background-color: var(--primary) !important;
                border-color: var(--primary) !important;
                color: var(--white) !important;
            }
        }

        &.disabled {
            opacity: 0.6;
        }
    }
}

.page-prev .page-link,
.page-next .page-link {
    border-radius: 3px;
    color: var(--textLite);

    @media(max-width: 1440px) {
        font-size: 13px !important;
    }

    span {
        line-height: normal;

        &:first-child {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 3px;
        }
    }
}

.float-right {
    float: right !important;
}

//   .scroll-top {
//       span {
//           display: flex;
//           align-items: center;
//           gap: 5px;
//           font-size: 16px;
//           color: var(--textLite);
//           cursor: pointer;

//           @media(max-width: 1440px) {
//               font-size: 63px;
//           }
//       }
//   }
